;(function ($) {

    'use strict';

    /**
     * todo: после закрытия модального окна, при последующем открытии, не работает автофокус
     */

    /**
     * Сам класс с методами для работы с плагином
     *
     * все изменения должны делаться именно здесь
     */
    var ActiveForm = function (elm, options) {
        var self,
            // инициализация
            init = function () {
                var $form = $(elm);

                let _beforeValidateHandler;
                let data = $.fn.jsHelpers('convertJsExpressions', $form.data());


                // если у функции есть параметр data-before-validate, то именно эту функцию привязываем
                if (data['beforeValidate'] != undefined) {

                    _beforeValidateHandler = data['beforeValidate'];

                    if (typeof _beforeValidateHandler != 'function') {
                        _beforeValidateHandler = _beforeValidate;
                    }
                } else {
                    _beforeValidateHandler = _beforeValidate;
                }
                // событие вызываемое формой до валидации
                $form.bind('beforeValidate', _beforeValidateHandler);


                let _afterValidateHandler;
                // если у функции есть параметр data-after-validate, то именно эту функцию привязываем
                if (data['afterValidate'] != undefined) {
                    _afterValidateHandler = data['afterValidate'];

                    if (typeof _afterValidateHandler != 'function') {
                        _afterValidateHandler = _afterValidate;
                    }
                } else {
                    _afterValidateHandler = _afterValidate;
                }
                // событие вызываемое формой после валидации
                $form.bind('afterValidate', _afterValidateHandler);



                let _beforeSubmitHandler;
                // если у функции есть параметр data-before-submit, то именно эту функцию привязываем
                if (data['beforeSubmit'] != undefined) {
                    _beforeSubmitHandler = data['beforeSubmit'];

                    if (typeof _beforeSubmitHandler != 'function') {
                        _beforeSubmitHandler = _beforeSubmit;
                    }
                } else {
                    _beforeSubmitHandler = _beforeSubmit;
                }

                // событие вызываемое формой после валидации и до отправки формы
                $form.bind('beforeSubmit', _beforeSubmitHandler);

            },

            // активировать кнопку
            enableSubmit = function () {
                $(elm).find(':submit').ladda('stop');
            },

            // сделать кнопку неактивной
            disableSubmit = function () {
                $(elm).find(':submit').ladda('start');
            },

            // Сброс формы (для вызова из других мест)
            reset = function () {
                // сбрасываем валидацию
                $(elm).yiiActiveForm('resetForm');
                // сбрасываем данные в форме
                $(elm).get(0).reset();
            },

            // событие вызываемое формой до валидации
            _beforeValidate = function (event, messages, deferreds) {
                disableSubmit();

                return true;
            },

            // событие вызываемое формой после валидации
            _afterValidate = function (event, messages, errorAttributes) {
                var $form = $(elm);

                // enable button if has errors
                if (errorAttributes.length > 0) {
                    // shake effect
                    var $cont = $form.closest('.modal-inner-container');

                    if ($cont.length == 0) {
                        $cont = $form;
                    } else if ($cont.closest('.coder-modal__scroller').length > 0) {
                        $cont = $cont.closest('.coder-modal__scroller');
                    }

                    if ($cont.length > 0 && $cont.effect != undefined) {
                        $cont.effect('shake', {distance: 6, times: 4});
                    }

                    // enable submit button
                    enableSubmit();
                } else { // валидация прошла, но форма модальная
                    if (options.modal) {
                        enableSubmit();
                    } else {
                        $form.find(':submit').prop('disabled', false);
                    }
                }

            },

            // событие вызываемое формой после валидации и до отправки формы
            _beforeSubmit = function (event) {
                event.preventDefault();

                var sendData = {};
                var $form = $(elm);

                if (!options.modal) {
                    // если форма в модальном окне, то не пропускаем submit
                    return true;
                }

                var params = {
                    url: this.action,
                    method: 'post',
                    dataType: 'json',
                    success: function (data, textStatus, jqXHR) {
                        var successCallback = $.ajaxSetup()['success'];

                        if (successCallback != undefined) {
                            successCallback(data, textStatus, jqXHR);
                        }

                        if (data.success) {

                            if (options.modal) {
                                $.fn.coderModal('close');
                            }

                            // генерурием событие afterSubmit
                            $form.trigger($.Event('afterSubmit'), [data]);
                        }

                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        enableSubmit($form);
                    }
                };

                if (this.enctype == 'multipart/form-data') {
                    sendData = new FormData(this);
                    params.processData = false;
                    params.contentType = false;

                } else {
                    sendData = $form.serialize();
                }

                params.data = sendData;

                $.ajax(params);

                return false;
            };

        self = {
            init,
            enableSubmit,
            disableSubmit,
            reset
        };

        return self;
    };

    /**
     * Плагин для работы с формами
     *
     * @param  string|object opt  либо имя функции, либо json с параметрами
     * @param  object opt2 json с параметрами
     *
     * возвращает либо сам объект для которого вызвали плагин,
     * либо значение которая возвращтит одна из вызванных функций
     *
     * @notes
     * Тут ничего не трогаем
     */
    $.fn.activeForm = function (opt, opt2) {

        var result = this;

        this.each(function () {

            var object;

            if (!$(this).data('activeForm')) {

                object = new ActiveForm(this, _.cloneDeep($.extend(true, defaultOptions, opt)));

                object.init();

                $(this).data('activeForm', object);
            } else {
                object = $(this).data('activeForm');
            }

            // для случаев когда нужно вызвать функцию
            if ($.type(opt) === 'string' && object[opt] !== undefined && $.isFunction(object[opt])) {
                result = object[opt](opt2);
            }
        });

        return result;

    };

    var defaultOptions = {modal: true};

    $.fn.activeForm.defaultOptions = defaultOptions;

}(jQuery));